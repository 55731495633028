import { useEffect, useRef } from 'react';

const useIntersection = (callback: IntersectionObserverCallback) => {
  const observerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(callback, {
      threshold: 0.1, // 필요에 따라 threshold를 조정하세요
    });

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [callback]);

  return { observerRef };
};

export default useIntersection;